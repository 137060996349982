<template>
  <div class="wallets">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">{{ titlePage }}</div>
            <div class="card-body">
              <div class="container form-container">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Nome da Carteira</label>
                      <span class="required-field">*</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="wallet.name"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-primary float-right btn-submit"
                      v-on:click="save()"
                    >
                      Salvar
                    </button>
                    <button
                      v-if="formType == 'edit'"
                      class="btn btn-primary float-right btn-destroy"
                      v-on:click="destroy()"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { bus } from "@/main";
import helper from "@/imports/Helpers";
import validator from "@/imports/validator/validate";
import wallet_validate from "@/imports/validator/models/wallet";

export default {
  name: "wallets",
  props: {
    id: Number,
  },
  components: {},
  data() {
    return {
      wallet: {},
      formType: "create",
      titlePage: "Nova Carteira",
      navigate_items: [
        {
          text: "Carteiras",
          route: "dashboard.wallets",
        },
        {
          text: "Criar",
          active: true,
        },
      ],
    };
  },
  mounted() {
    if (this.id) {
      this.formType = "edit";
      this.navigate_items[1].text = "Editar";
      this.getDataObject(this.id);
    }
  },
  methods: {
    getDataObject: function () {
      bus.$emit("changeLoading", true);
      helper.getDataObject("wallets", this.id).then((response) => {
        this.wallet = response.data;
        this.titlePage = this.wallet.name;
        bus.$emit("changeLoading", false);
      });
    },
    save: function () {
      let errors = validator.validate(this.wallet, wallet_validate.model);
      if (errors.length == 0) {
        helper
          .saveDataObject(
            "wallets",
            this.wallet,
            this.formType,
            "Carteira",
            "a"
          )
          .then(() => {
            this.$router.push({ name: "dashboard.wallets" });
          });
      } else {
        helper.showErrorsForm(errors);
      }
    },
    destroy: function () {
      helper
        .destroyDataObject(
          "wallets",
          this.wallet,
          "",
          "Certifique-se que não existem clientes nesta carteira"
        )
        .then(() => {
          this.$router.push({ name: "dashboard.wallets" });
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
</style>